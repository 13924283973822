import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "config";
import { AuthChecker, MainLayout } from "components";
import React, { Suspense } from "react";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <MainLayout>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {routes.map((route, index) => {
              const Component = route.component;
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    route.protected || route.loggedOutOnly ? (
                      <AuthChecker inverted={route.loggedOutOnly}>
                        <Component />
                      </AuthChecker>
                    ) : (
                      <Component />
                    )
                  }
                />
              );
            })}
          </Routes>
        </Suspense>
      </MainLayout>
    </BrowserRouter>
  );
}

export default App;
