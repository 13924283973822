import { lazy } from "react";

interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
  loggedOutOnly: boolean;
}

// These will appear in the navbar
export const mainRoutes: RouteType[] = [
  {
    path: "",
    component: lazy(() => import("screens/Home")),
    name: "Home",
    protected: false,
    loggedOutOnly: false,
  },
  {
    path: "/picker",
    component: lazy(() => import("screens/Picker")),
    name: "Picker",
    protected: false,
    loggedOutOnly: false,
  },
  {
    path: "/ratings",
    component: lazy(() => import("screens/Ratings")),
    name: "Ratings",
    protected: false,
    loggedOutOnly: false,
  },
];

export const userRoutes: RouteType[] = [
  {
    path: "/login",
    component: lazy(() => import("screens/Login")),
    name: "Login",
    protected: false,
    loggedOutOnly: true,
  },
  {
    path: "/logout",
    component: lazy(() => import("components/auth/Logout")),
    name: "Logout",
    protected: true,
    loggedOutOnly: false,
  },
  {
    path: "/brawlers",
    component: lazy(() => import("screens/Brawlers")),
    name: "Brawlers",
    protected: false,
    loggedOutOnly: false,
  },
  {
    path: "/maps",
    component: lazy(() => import("screens/Maps")),
    name: "Maps",
    protected: false,
    loggedOutOnly: false,
  },
  {
    path: "/modes",
    component: lazy(() => import("screens/GameModes")),
    name: "Game Modes",
    protected: false,
    loggedOutOnly: false,
  },
  {
    path: "/modifiers",
    component: lazy(() => import("screens/Modifiers")),
    name: "Modifiers",
    protected: false,
    loggedOutOnly: false,
  },
];

// These will not appear in the navbar
export const passiveRoutes: RouteType[] = [];

const routes: RouteType[] = [...mainRoutes, ...userRoutes, ...passiveRoutes];
export default routes;
