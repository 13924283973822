import { Stack } from "@mui/material";
import Image from "./Image";

type Props = {
  title: string;
  src: string;
  background: string;
  onSelect?: () => void;
  selected?: boolean;
};

export default function Selector({
  title,
  src,
  background,
  onSelect,
  selected,
}: Props) {
  return (
    <Stack
      sx={{
        background,
        cursor: "pointer",
        border: selected ? "8px solid #30e5d9" : "8px solid transparent",
        "&:hover": {
          filter: "brightness(0.75)",
        },
      }}
      onClick={onSelect}
      px={2}
      py={1}
      alignItems="center"
      justifyContent="center"
    >
      <Image src={src} alt={title} />
    </Stack>
  );
}
