import { useState, useEffect } from "react";
import { ref, get, set } from "firebase/database";
import { database } from "config";

export function useBrawlerRatings(
  gameMode: string,
  map: string,
  modifier: string,
  brawlerKey: string
) {
  const [rating, setRatingState] = useState<number>(0);

  function getRatingRef() {
    return ref(
      database,
      `ratings/${gameMode}/${map}/${modifier}/${brawlerKey}`
    );
  }

  async function updateRatings() {
    const ref = getRatingRef();
    const snapshot = await get(ref);
    const data = snapshot.val();
    if (!data) {
      setRatingState(0);
      return;
    } else {
      setRatingState(data.rating);
    }
  }

  function setRating(value: number) {
    const ref = getRatingRef();
    set(ref, {
      rating: value,
    });
    setRatingState(value);
  }

  function deleteRating() {
    const ref = getRatingRef();
    set(ref, null);
    setRatingState(0);
  }

  useEffect(
    () => {
      updateRatings();
    }, // eslint-disable-next-line
    [gameMode, map, modifier]
  );

  return { rating, setRating, deleteRating };
}
