import { useEffect, useState } from "react";
import { ref, get, set } from "firebase/database";
import { database } from "config";

interface IGameMode {
  key: string;
  name: string;
  src: string;
  background: string;
}

export function useGameModes() {
  const [gameModes, setGameModes] = useState<IGameMode[]>([]);

  function getGameModeRef(gameModeName: string = "") {
    if (gameModeName === "") return ref(database, "gameModes");
    return ref(database, `gameModes/${gameModeName}`);
  }

  async function updateGameModes() {
    const ref = getGameModeRef();
    const snapshot = await get(ref);
    const data = snapshot.val();
    let gameModes: IGameMode[] = [];
    for (let key in data) {
      gameModes.push({
        key,
        name: data[key].name,
        src: data[key].src,
        background: data[key].background,
      });
    }
    setGameModes(gameModes);
  }

  function addGameMode(
    key: string,
    name: string,
    src: string,
    background: string
  ) {
    const ref = getGameModeRef(key);
    set(ref, {
      name,
      src,
      background,
    });
    updateGameModes();
  }

  useEffect(
    () => {
      updateGameModes();
    },
    // eslint-disable-next-line
    []
  );

  return { gameModes, addGameMode };
}
