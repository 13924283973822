import { useEffect, useState } from "react";
import { ref, get, set } from "firebase/database";
import { database } from "config";

interface IBrawler {
  key: string;
  name: string;
  src: string;
}

export function useBrawlers() {
  const [brawlers, setBrawlersState] = useState<IBrawler[]>([]);

  function getBrawlerRef(brawlerName: string = "") {
    if (brawlerName === "") return ref(database, "brawlers");
    return ref(database, `brawlers/${brawlerName}`);
  }

  async function updateBrawlers() {
    const brawlerRef = getBrawlerRef();
    const snapshot = await get(brawlerRef);
    const data = snapshot.val();
    const brawlers = Object.keys(data).map((key) => ({
      key,
      ...data[key],
    }));
    setBrawlersState(brawlers);
  }

  useEffect(() => {
    updateBrawlers(); // eslint-disable-next-line
  }, []);

  async function addBrawler(key: string, name: string, src: string) {
    const brawlerRef = getBrawlerRef(key);

    await set(brawlerRef, {
      name,
      src,
    });
    updateBrawlers();
  }

  return { brawlers, addBrawler };
}
