import { useEffect, useState } from "react";
import { ref, get, set } from "firebase/database";
import { database } from "config";

interface IModifier {
  key: string;
  name: string;
  src: string;
}

export function useModifiers() {
  const [modifiers, setModifiers] = useState<IModifier[]>([]);

  function getModifiersRef(modifierName: string = "") {
    if (modifierName === "") return ref(database, "modifiers");
    return ref(database, `modifiers/${modifierName}`);
  }

  async function updateModifiers() {
    const ref = getModifiersRef();
    const snapshot = await get(ref);
    const data = snapshot.val();
    console.log(data);
    let recievedModifiers: IModifier[] = [];
    for (let key in data) {
      if (data[key].active) {
        recievedModifiers.push({
          key,
          name: data[key].name,
          src: data[key].src,
        });
      }
    }
    setModifiers(recievedModifiers);
  }

  function addModifier(key: string, name: string, src: string) {
    const ref = getModifiersRef(key);
    set(ref, {
      name,
      src,
    });
    updateModifiers();
  }

  useEffect(
    () => {
      updateModifiers();
    }, // eslint-disable-next-line
    []
  );

  return { modifiers, addModifier };
}
