import React from "react";
import Navbar from "./Navbar";
import { Container, Stack } from "@mui/material";

type Props = {
  children?: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
  return (
    <Stack>
      <Navbar />
      <Container>{children}</Container>
    </Stack>
  );
}
