import { useState, useEffect } from "react";
import { ref, get } from "firebase/database";
import { database } from "config";

type Ratings = Record<string, { rating: number }>;

export function useRating(gameMode: string, map: string, modifier: string) {
  const [ratings, setRatings] = useState<Ratings>({} as Ratings);

  function getRatingRef(brawler: string = "") {
    console.log(gameMode, map, modifier, brawler);
    if (brawler === "")
      return ref(database, `ratings/${gameMode}/${map}/${modifier}`);
    return ref(database, `ratings/${gameMode}/${map}/${modifier}/${brawler}`);
  }

  async function updateRatings() {
    const ref = getRatingRef();
    const snapshot = await get(ref);
    const data = snapshot.val();
    setRatings(data);
  }

  useEffect(
    () => {
      updateRatings();
    }, // eslint-disable-next-line
    [gameMode, map, modifier]
  );

  return { ratings, updateRatings };
}
