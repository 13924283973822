import { useState } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "config";

export function useAuth() {
  const [storedAuth, setAuth] = useState<User | null>(null);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setAuth(user);
    } else {
      setAuth(null);
    }
  });

  return storedAuth;
}
