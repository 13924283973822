import { useEffect, useState } from "react";
import { ref, get, set } from "firebase/database";
import { database } from "config";

type IMapBrawler = Record<string, number>;

interface IMap {
  key: string;
  name: string;
  src: string;
  background: string;
  brawlers: Record<string, IMapBrawler>;
}

export function useMaps(gameMode: string) {
  const [maps, setMaps] = useState<IMap[]>([]);

  function getMapRef(gameMode: string, mapKey: string | null = null) {
    if (mapKey === null) return ref(database, `maps/${gameMode}`);
    return ref(database, `maps/${gameMode}/${mapKey}`);
  }

  async function updateMap(gameMode: string) {
    if (gameMode === "") return;
    const ref = getMapRef(gameMode);
    const snapshot = await get(ref);
    const data = snapshot.val();
    let mapsResponse: IMap[] = [];
    for (let key in data) {
      mapsResponse.push({
        key,
        name: data[key].name,
        src: data[key].src,
        background: data[key].background,
        brawlers: data[key].brawlers,
      });
    }
    setMaps(mapsResponse);
  }

  useEffect(() => {
    updateMap(gameMode);
    // eslint-disable-next-line
  }, [gameMode]);

  function addMap(
    gameMode: string,
    mapKey: string,
    name: string,
    src: string,
    background: string
  ) {
    const ref = getMapRef(gameMode, mapKey);
    set(ref, {
      name,
      src,
      background,
    });
    updateMap(gameMode);
  }
  return {
    maps,
    addMap,
  };
}
