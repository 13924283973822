import { useEffect, useState } from "react";
import { ref, get, set } from "firebase/database";
import { database } from "config";

interface IBrawler {
  key: string;
  name: string;
  src: string;
}

export function useBrawler(brawlerName: string) {
  const [brawler, setBrawlerState] = useState<IBrawler | null>(null);

  function getBrawlerRef(brawlerName: string) {
    return ref(database, `brawlers/${brawlerName}`);
  }

  async function updateBrawler(brawlerName: string) {
    if (brawlerName === "") return;

    const brawlerRef = getBrawlerRef(brawlerName);
    const snapshot = await get(brawlerRef);
    const data = snapshot.val();
    setBrawlerState(data);
  }

  useEffect(() => {
    updateBrawler(brawlerName); // eslint-disable-next-line
  }, [brawlerName]);

  async function setBrawler(name: string, src: string) {
    const brawlerRef = getBrawlerRef(name);
    await set(brawlerRef, {
      name,
      src,
    });
    updateBrawler(name);
  }

  return { brawler, setBrawler };
}
