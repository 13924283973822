import styled from "@emotion/styled";

const Image = styled.img`
  width: 5rem;
  cursor: pointer;
  border-radius: 4px;
  object-fit: cover;
  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.75);
  }
`;
export default Image;
